import { useEffect, useState } from "react";
import { RecordInvoiceShow } from "../modules/api/RecordInvoiceAPI";
import { useNavigate, useParams } from "react-router-dom";
import { StatusFormat } from "../modules/widgets/form/StatusFormat";
import {
  ConvertMonthTHFull,
  ConvertTime3,
} from "../modules/widgets/form/ConvertTime";
import { Tab } from "@headlessui/react";
import AmountGraph from "../modules/components/record-invoice/AmountGraph";
import UnitGraph from "../modules/components/record-invoice/UnitGraph";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import { Nevbar } from "../modules/layout/Navbar";
import { ProgressSpinner } from "primereact/progressspinner";
import { Dialog } from "primereact/dialog";
import { toAbsoluteUrl } from "../modules/utils/AssetHelpers";
import { DetailModal } from "../modules/components/record-invoice/DetailModal";
import Swal from "sweetalert2";

const userIcon = (
  <FontAwesomeIcon
    icon={faUser}
    className="me-2"
    style={{
      color: "#6F6B7D",
      width: "16px",
      height: "16px",
      marginBottom: "2px",
    }}
  />
);

const ellipsisVerticalIcon = (
  <FontAwesomeIcon icon={faEllipsisVertical} className="" />
);

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

const RecordInvoiceForm = () => {
  const param = useParams();
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState({
    id: null,
    name: "",
    area_number: "",
    water_number: "",
    meter_number: "",
  });
  const [dataList, setDataList] = useState({
    amountPaid: 0,
    outstandingBalance: 0,
    latestMonth: "",
    totalMonth: 0,
    currentUnit: 0,
    latest: {},
    total: 0,
  });
  const [visible, setVisible] = useState(false);
  const [modalData, setModalData] = useState(false);

  const getUser = (
    data: any,
    area_number: string,
    water_number: string,
    meter_number: string
  ) => {
    setUser({
      id: null,
      name: "",
      area_number: area_number,
      water_number: water_number,
      meter_number: meter_number,
    });
  };

  const getDataList = (data: any) => {
    let currentTotal: number = 0;
    let currentUnit: number = 0;
    let totalMonth: number = 0;
    let total: number = 0;

    const pendingList: any = data.filter(
      (item: any) => item.status === "pending"
    );

    const pendingTotal: any = pendingList.reduce(
      (sum: number, item: { total: number }) => sum + Number(item?.total),
      0
    );

    const latestMonth: any = data.reduce((latestMonth: any, item: any) => {
      return latestMonth.issue_date > item.issue_date ? latestMonth : item;
    }, {});

    if (latestMonth?.status === "pending") {
      currentTotal = latestMonth?.total || 0;
    }

    let date_now = new Date();

    totalMonth = pendingList.reduce((sum: any, item: any) => {
      const dueDate = new Date(item?.due_date);

      if (dueDate < date_now) {
        sum = sum + 1;
      }

      return sum;
    }, 0);

    total = data.reduce((sum: any, item: any) => {
      sum = sum + Number(item.total);
      return sum;
    }, 0);

    currentUnit = latestMonth?.water_meter_record?.sum_unit;

    setDataList({
      amountPaid: currentTotal || 0,
      outstandingBalance: pendingTotal || 0,
      latestMonth: ConvertMonthTHFull(latestMonth?.issue_date) || "",
      totalMonth: totalMonth || 0,
      currentUnit: currentUnit || 0,
      latest: latestMonth || {},
      total: Number(total.toFixed(3)) || 0,
    });
  };

  const getData = async () => {
    const area_number = param?.area_number || "";
    const water_number = param?.water_number || "";
    const meter_number = param?.meter_number || "";
    try {
      const res: any = await RecordInvoiceShow(
        area_number,
        water_number,
        meter_number
      );
      if (res?.data?.responseStatus === "true") {
        const result: any = res?.data?.data || [];
        setData(result);
        getUser(result, area_number, water_number, meter_number);
        getDataList(result);
        setIsLoading(false);
      } else if (
        res?.data?.responseCode === "403" ||
        res?.data?.responseCode === "422"
      ) {
        let message: any = res?.data?.responseMessage || "";
        Swal.fire({
          title: "ไม่สามารถเข้าใช้งานได้",
          text: message,
          icon: "error",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "ตกลง",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/");
          }
        });
      } else {
        Swal.fire({
          title: "ไม่สามารถเข้าใช้งานได้",
          text: "กรุณาเข้าใช้งานใหม่อีกครั้ง",
          icon: "error",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "ตกลง",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/");
          }
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (isLoading) {
      getData();
    }
  }, [isLoading, user, dataList, getData]);

  return (
    <>
      {isLoading ? (
        <>
          <Nevbar />
          <div className="center-screen-2">
            <ProgressSpinner
              style={{ width: "50px", height: "50px" }}
              strokeWidth="8"
              fill="var(--surface-ground)"
              aria-label="Loading"
            />
          </div>
        </>
      ) : (
        <>
          <div
            className="bg-invoice"
            style={{
              backgroundImage: `url('${toAbsoluteUrl("/images/bg-2.svg")}')`,
            }}
          >
            <Nevbar />
            <div className="container">
              <div className="row">
                <div className="col-12 mb-4">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="user-data shadow-sm">
                        <div className="text-6F6B7D fs-5 mt-auto">
                          <div>{userIcon}ข้อมูลผู้ใช้น้ำ</div>
                        </div>
                        <div className="text-6F6B7D fs-5 mt-auto">
                          เลขที่ผู้ใช้น้ำ:{" "}
                          <span className="text-01A867">
                            {user.water_number}
                          </span>
                        </div>
                        <div className="text-6F6B7D fs-5 mt-auto">
                          เลขที่เขต:{" "}
                          <span className="text-01A867">
                            {user.area_number}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-6 mb-4">
                  <div className="row">
                    <div className="col-sm-6 mb-2">
                      <div
                        className="text-white custom-card-2 bg-invoice-crad shadow-sm"
                        style={{
                          backgroundImage: `url('${toAbsoluteUrl(
                            "/images/crads/1.svg"
                          )}')`,
                        }}
                      >
                        <div className="fs-4">เดือน</div>
                        <div
                          className="d-flex justify-content-start align-items-start"
                          style={{ marginTop: "-10px" }}
                        >
                          <div className="fs-2">
                            {dataList?.latestMonth || ""}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 mb-2">
                      <div
                        className="text-6F6B7D custom-card-2 bg-invoice-crad shadow-sm"
                        style={{
                          backgroundImage: `url('${toAbsoluteUrl(
                            "/images/crads/2.svg"
                          )}')`,
                        }}
                      >
                        <div className="fs-4">ยอดชำระ</div>
                        <div
                          className="d-flex justify-content-start align-items-start text-01A867"
                          style={{ marginTop: "-10px" }}
                        >
                          <div className="fs-2">
                            {dataList?.amountPaid || 0} บาท
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 mb-2">
                      <div
                        className="text-white custom-card-2 bg-invoice-crad shadow-sm"
                        style={{
                          backgroundImage: `url('${toAbsoluteUrl(
                            "/images/crads/3.svg"
                          )}')`,
                        }}
                      >
                        <div className="fs-4">
                          จำนวนเดือนที่ค้างชำระ
                        </div>
                        <div
                          className="d-flex justify-content-start align-items-start"
                          style={{ marginTop: "-10px" }}
                        >
                          <div className="fs-2">
                            {dataList?.totalMonth || 0} เดือน
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 mb-2">
                      <div
                        className="text-6F6B7D custom-card-2 bg-invoice-crad shadow-sm"
                        style={{
                          backgroundImage: `url('${toAbsoluteUrl(
                            "/images/crads/4.svg"
                          )}')`,
                        }}
                      >
                        <div className="fs-4">ยอดค้างชำระ</div>
                        <div
                          className="d-flex justify-content-start align-items-start text-danger"
                          style={{ marginTop: "-10px" }}
                        >
                          <div className="fs-2">
                            {dataList?.outstandingBalance || 0} บาท
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-6 mb-4">
                  <div className="card border-0 shadow-sm">
                    <div className="card-body">
                      <Tab.Group>
                        <Tab.List className="mb-2">
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="d-flex align-items-center text-tab">
                              <div className="text-6F6B7D me-2 fs-5">
                                ข้อมูลผู้ใช้น้ำ
                              </div>
                              <Tab
                                className={({ selected }) =>
                                  classNames(
                                    "fs-5 me-2",
                                    selected ? "btn-tab-seleted-2 px-3" : "btn-tab-2 px-3"
                                  )
                                }
                              >
                                จำนวนเงิน
                              </Tab>
                              <Tab
                                className={({ selected }) =>
                                  classNames(
                                    "fs-5",
                                    selected ? "btn-tab-seleted-2 px-3" : "btn-tab-2 px-3"
                                  )
                                }
                              >
                                จำนวนหน่วย
                              </Tab>
                            </div>
                            <button className="btn btn-sm">
                              {ellipsisVerticalIcon}
                            </button>
                          </div>
                        </Tab.List>
                        <Tab.Panels>
                          <Tab.Panel>
                            <AmountGraph data={data} />
                          </Tab.Panel>
                          <Tab.Panel>
                            <UnitGraph data={data} />
                          </Tab.Panel>
                        </Tab.Panels>
                      </Tab.Group>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mb-4">
                  <div className="table-responsive">
                    <table
                      className="table table-striped text-center custom-rounded-table table-hover shadow-sm"
                      style={{ background: "transparent", minWidth: "500px" }}
                    >
                      <thead className="bg-table-gradient">
                        <tr>
                          <th className="text-white">รอบบิล</th>
                          <th className="text-white">วันที่จดหน่วย</th>
                          <th className="text-white">วันที่ชำระ</th>
                          <th className="text-white">บาท</th>
                        </tr>
                      </thead>
                      <tbody className="bg-white">
                        {data?.length > 0
                          ? data?.map((val: any, index: number) => {
                              return (
                                <tr
                                  key={`table_${index}`}
                                  onClick={() => {
                                    setModalData(val);
                                    setVisible(true);
                                  }}
                                >
                                  <td>{val.issue_date_month_format}</td>
                                  <td>
                                    {val?.water_meter_record
                                      ? ConvertTime3(
                                          val.water_meter_record.record_date
                                        )
                                      : "-"}
                                  </td>
                                  <td>{StatusFormat(val)}</td>
                                  <td>{val.total}</td>
                                </tr>
                              );
                            })
                          : null}
                      </tbody>
                      <tfoot className="bg-white">
                        <tr>
                          <td></td>
                          <td></td>
                          <td className="text-01A867">รวม</td>
                          <td className="text-01A867">
                            {dataList?.total || 0} บาท
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {/* modal */}
      <Dialog
        header={
          <>
            <div className="d-flex justify-content-start align-items-center mb-4">
              <img
                src={toAbsoluteUrl("/images/logo.svg")}
                className="img-fluid rounded-start me-2"
                alt="..."
                style={{ height: "30px" }}
              />
              <div
                className="d-flex flex-column justify-content-center text-6F6B7D"
                style={{ fontSize: "12px" }}
              >
                <strong>ตรวจสอบข้อมูลการใช้น้ำ</strong>
                <span>สำนักการประปาเทศบาลนครนครราชสีมา</span>
              </div>
            </div>
            <div
              className="fs-4 text-6F6B7D"
              style={{ borderBottom: "1px solid #6F6B7D" }}
            >
              รายละเอียดการใช้น้ำ
            </div>
          </>
        }
        visible={visible}
        className="custom-modal"
        onHide={() => setVisible(false)}
      >
        <DetailModal data={modalData} />
      </Dialog>
    </>
  );
};

const RecordInvoicePage = () => {
  return (
    <>
      <RecordInvoiceForm />
    </>
  );
};

export default RecordInvoicePage;
