import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { toAbsoluteUrl } from "../modules/utils/AssetHelpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";

const infoIcon = <FontAwesomeIcon icon={faCircleInfo} className="me-2" />;

const HomeContent = ({
  children,
  desktop,
}: {
  children: React.ReactNode;
  desktop: boolean;
}) => (
  <>
    {/* title */}

    <div className="d-flex justify-content-center align-items-center mb-5">
      <img
        src={toAbsoluteUrl("/images/logo.svg")}
        className="img-fluid rounded-start me-2"
        alt="..."
        style={{ height: "85px" }}
      />
      <div className="d-flex flex-column justify-content-center">
        <div className={desktop ? "fs-2 " : "fs-4 "}>
          ระบบตรวจสอบข้อมูลผู้ใช้น้ำ
        </div>
        <div className={desktop ? "fs-5 " : ""}>
          สำนักการประปาเทศบาลนครนครราชสีมา
        </div>
      </div>
    </div>
    {/* form */}
    <div className={desktop ? "col-sm-6" : "p-5"}>{children}</div>
  </>
);

const HomeForm = () => {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data: any) => {
    navigate(
      `/pecord-invoice/${data.area_number}/${data.water_number}/${data.meter_number}`
    );
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1120);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1120);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div className="bg-defalt text-6F6B7D">
        <div
          className="row g-0"
          style={
            isMobile
              ? {
                  height: "100vh",
                }
              : {
                  height: "100vh",
                  backgroundImage: `url('${toAbsoluteUrl("/images/bg.svg")}')`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "left",
                }
          }
        >
          <div className={isMobile ? "" : "col-md-8 col-lg-7"}></div>
          <div
            className={
              isMobile
                ? "d-flex justify-content-center align-items-center position-relative flex-column"
                : "col-md-4 col-lg-5 d-flex justify-content-center align-items-center position-relative flex-column"
            }
          >
            <HomeContent desktop={isMobile ? false : true}>
              <h2 className="mb-4 fs-4 ">กรอกหมายเลขผู้ใช้น้ำ</h2>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row g-4">
                  <div className="col-md-8 col-12 mb-2">
                    <label htmlFor="water_number" className="fs-5 ">
                      เลขที่ผู้ใช้น้ำ
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("water_number", {
                        required: "กรุณากรอกเลขที่ผู้ใช้น้ำ",
                      })}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="water_number"
                      render={({ message }) => (
                        <p className="text-danger">{message}</p>
                      )}
                    />
                  </div>
                  <div className="col-md-4 col-12 mb-2">
                    <label htmlFor="area_number" className="fs-5 ">
                      เลขที่เขต
                    </label>
                    <input
                      autoFocus
                      type="text"
                      className="form-control"
                      onInput={() => {}}
                      {...register("area_number", {
                        required: "กรุณากรอกเลขที่เขต",
                      })}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="area_number"
                      render={({ message }) => (
                        <p className="text-danger">{message}</p>
                      )}
                    />
                  </div>
                  <div className="col-12 mb-2">
                    <label htmlFor="meter_number" className="fs-5 ">
                      รหัสมาตรวัดน้ำ
                    </label>
                    <input
                      autoFocus
                      type="text"
                      className="form-control"
                      onInput={() => {}}
                      {...register("meter_number", {
                        required: "กรุณากรอกรหัสมาตรวัดน้ำ",
                      })}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="meter_number"
                      render={({ message }) => (
                        <p className="text-danger">{message}</p>
                      )}
                    />
                  </div>
                  <div className="col-12">
                    <button
                      type="submit"
                      className="btn btn-login fs-5 fw-normal"
                    >
                      เข้าสู่ะบบ
                    </button>
                  </div>
                </div>
              </form>
              {/* description */}
              <p className="mt-4">
                {infoIcon}
                กรุณาตรวจสอบเลขที่ผู้ใช้น้ำและรหัสมาตรวัดน้ำ
                ในใบแจ้งหนี้หรือใบเสร็จที่ออกจากสำนักการประปา
                เพื่อทำการตรวจสอบข้อมูลเกี่ยวกับการใช้น้ำของท่าน
              </p>
            </HomeContent>
          </div>
        </div>
      </div>
    </>
  );
};

const HomePage = () => {
  return (
    <>
      <HomeForm />
    </>
  );
};

export default HomePage;
