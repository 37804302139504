import React, { useState, useEffect } from "react";
import { Chart } from "primereact/chart";
import { ConvertMonthTH } from "../../widgets/form/ConvertTime";
import { InterpolateColor } from "../../utils/InterpolateColor";

const UnitGraph = ({ data }: any) => {
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    const getAmount = () => {
      let data_list: any = [];
      data?.forEach((val: any) => {
        if (val.water_meter_record) {
          let dateNow: any = new Date();
          let issueDate: any = new Date(val.issue_date);
          if (issueDate.getFullYear() >= dateNow.getFullYear()) {
            const existingData = data_list?.find(
              (item: any) => item.month === val.issue_date
            );
            if (existingData) {
              existingData.unit += val.water_meter_record.sum_unit;
            } else {
              data_list.push({
                month: val.issue_date,
                unit: val.water_meter_record.sum_unit,
              });
            }
          }
        }
      });

      data_list.sort(
        (a: any, b: any) =>
          new Date(a?.month).getTime() - new Date(b?.month).getTime()
      );

      const months = data_list.map((val: any) => ConvertMonthTH(val.month));
      const units = data_list.map((val: any) => val.unit);

      const documentStyle = getComputedStyle(document.documentElement);
      const textColor = documentStyle.getPropertyValue("--text-color");
      // const textColorSecondary = documentStyle.getPropertyValue(
      //   "--text-color-secondary"
      // );
      const surfaceBorder = documentStyle.getPropertyValue("--surface-border");

      const graph_data = {
        labels: months,
        datasets: [
          {
            label: "จำนวนหน่วย",
            data: units,
            fill: false,
            borderRadius: 10,
            backgroundColor: (context: any) => {
              const gradient = context.chart.canvas
                .getContext("2d")
                .createLinearGradient(0, 0, 0, 300);

              const numberOfMonths = months.length;

              for (let i = 0; i < numberOfMonths; i++) {
                const percent = i / (numberOfMonths - 1);
                const color = InterpolateColor("#6FC3E9", "#04BB73", percent);
                gradient.addColorStop(percent, color);
              }

              return gradient;
            },
            tension: 0.4,
          },
        ],
      };
      const options = {
        maintainAspectRatio: false,
        aspectRatio: 0.6,
        plugins: {
          legend: {
            labels: {
              color: textColor,
            },
          },
        },
        scales: {
          x: {
            ticks: {
              color: "#01a8687c",
            },
            grid: {
              color: surfaceBorder,
            },
          },
          y: {
            ticks: {
              color: "#01a8687c",
            },
            grid: {
              color: surfaceBorder,
            },
          },
        },
      };

      setChartData(graph_data);
      setChartOptions(options);
    };

    getAmount();
  }, [data]);

  return (
    <Chart
      type="bar"
      data={chartData}
      options={chartOptions}
      style={{ height: "300px" }}
    />
  );
};

export default UnitGraph;
