const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const monthTH = [
  "ม.ค.",
  "ก.พ.",
  "มี.ค.",
  "เม.ย.",
  "พ.ค.",
  "มิ.ย.",
  "ก.ค.",
  "ส.ค.",
  "ก.ย.",
  "ต.ค.",
  "พ.ย.",
  "ธ.ค.",
];

const monthTHFull = [
  "มกราคม",
  "กุมภาพันธ์",
  "มีนาคม",
  "เมษายน",
  "พฤษภาคม",
  "มิถุนายน",
  "กรกฎาคม",
  "สิงหาคม",
  "กันยายน",
  "ตุลาคม",
  "พฤศจิกายน",
  "ธันวาคม",
];

const addZero = (i: number) => {
  let index: string = "";
  if (i < 10) {
    index = "0" + i;
  } else {
    index = "" + i;
  }
  return index;
};

const ConvertTime = (time: string) => {
  if (time === "" || time === null || typeof time === "undefined") {
    return <div className="text-center">-</div>;
  } else {
    let current: any = new Date(time);
    return (
      <div>
        {addZero(current.getDate())}&nbsp;{monthNames[current.getMonth()]}&nbsp;
        {current.getFullYear()}
      </div>
    );
  }
};

const ConvertTime2 = (time: string) => {
  if (time === "" || time === null || typeof time === "undefined") {
    return <div className="text-center">-</div>;
  } else {
    let current: any = new Date(time);
    return (
      <div>
        {addZero(current.getDate())}/{addZero(current.getMonth() + 1)}/
        {current.getFullYear()}
      </div>
    );
  }
};

const ConvertTime3 = (time: string) => {
  if (time === "" || time === null || typeof time === "undefined") {
    return <div className="text-center">-</div>;
  } else {
    let current: any = new Date(time);
    return (
      <div>
        {addZero(current.getDate())}-{addZero(current.getMonth() + 1)}-
        {Number(current.getFullYear() + 543)}
      </div>
    );
  }
};

const ConvertTimeFormat = (time: string, format: string, lg: string) => {
  if (time === "" || time === null || typeof time === "undefined") {
    return <div className="text-center">-</div>;
  } else {
    let current: any = new Date(time);
    let date: any = addZero(current.getDate());
    let month: any = addZero(current.getMonth() + 1);
    let year: any = Number(current.getFullYear());

    if (lg === "th") {
      year = year + 543;
    }

    if (format === "dd-mm-yy") {
      return `${date}-${month}-${year}`;
    } else if (format === "dd/mm/yy") {
      return `${date}/${month}/${year}`;
    } else if (format === "yy/mm/dd") {
      return `${year}/${month}/${date}`;
    }

    return "";
  }
};

const ConvertMonthTH = (time: string) => {
  if (time === "" || time === null || typeof time === "undefined") {
    return "";
  } else {
    let current: any = new Date(time);
    return `${monthTH[current.getMonth()]}`;
  }
};

const ConvertMonthTHFull = (time: string) => {
  if (time === "" || time === null || typeof time === "undefined") {
    return "";
  } else {
    let current: any = new Date(time);
    return `${monthTHFull[current.getMonth()]}`;
  }
};

const ConvertTH = (time: string) => {
  if (time === "" || time === null || typeof time === "undefined") {
    return "";
  } else {
    let current: any = new Date(time);
    return (
      <div>
        {addZero(current.getDate())} {monthTHFull[current.getMonth()]}{" "}
        {Number(current.getFullYear() + 543)}
      </div>
    );
  }
};

export {
  ConvertTime,
  ConvertTime2,
  ConvertTime3,
  ConvertMonthTH,
  ConvertMonthTHFull,
  ConvertTH,
  ConvertTimeFormat,
};
