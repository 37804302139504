import { ConvertTime3, ConvertTimeFormat } from "./ConvertTime";

const StatusFormat = (data: any) => {
  let status: string = data?.status || "";
  let date: string = data?.record_bill?.issue_date || "";
  let due_date: any = new Date(data?.due_date);
  let date_now: any = new Date();

  if (status === "pending" && due_date < date_now) {
    return <div className="badge rounded-pill text-bg-warning">ยังไม่ชำระ</div>;
  } else if (status === "pending" && due_date > date_now) {
    return (
      <div>ชำระก่อนวันที่ {ConvertTimeFormat(due_date, "dd-mm-yy", "th")}</div>
    );
  } else if (status === "paymented") {
    return <div>{ConvertTime3(date)}</div>;
  } else {
    return <div>{status}</div>;
  }
};

export { StatusFormat };
