const InterpolateColor = (start: string, end: string, percent: number) => {
  const getColorComponent = (color: string, index: number) =>
    parseInt(color.slice(index, index + 2), 16);

  const interpolate = (start: number, end: number) =>
    Math.floor(start + (end - start) * percent);

  const r = interpolate(getColorComponent(start, 1), getColorComponent(end, 1));
  const g = interpolate(getColorComponent(start, 3), getColorComponent(end, 3));
  const b = interpolate(getColorComponent(start, 5), getColorComponent(end, 5));

  return `rgb(${r},${g},${b})`;
};

// const generateGradient = (context: any) => {
//     const numberOfMonths = months.length;
//     const gradient = context.chart.canvas
//       .getContext("2d")
//       .createLinearGradient(0, 0, 0, 300);
  
//     months.forEach((month: any, index: number) => {
//       const percent = (index / (numberOfMonths - 1)) * 100;
//       const color = `rgba(111, 195, 233, 1) ${percent}%, rgba(4, 187, 115, 1) ${percent}%`;
//       gradient.addColorStop(index / (numberOfMonths - 1), color);
//     });
  
//     return gradient;
//   };

export { InterpolateColor };
