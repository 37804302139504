import { toAbsoluteUrl } from "../utils/AssetHelpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

const logoutIcon = (
  <FontAwesomeIcon icon={faRightFromBracket} className="fa-fw me-2" />
);

const Nevbar = () => {
  const navigate = useNavigate();

  const backLogin = () => {
    navigate("/");
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 700);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 700);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div className={isMobile ? "nevbar-moblie mb-3" : "mb-5"}>
        <div className="container d-flex justify-content-between pt-2 pb-2 align-items-center">
          <div className="d-flex justify-content-center align-items-center ">
            <img
              src={toAbsoluteUrl("/images/logo.svg")}
              className="img-fluid rounded-start me-2"
              alt="..."
              style={{ height: "40px" }}
            />
            <div className="d-flex flex-column justify-content-center text-6F6B7D">
              <strong>ระบบตรวจสอบข้อมูลผู้ใช้น้ำ</strong>
              <span>สำนักการประปาเทศบาลนครนครราชสีมา</span>
            </div>
          </div>
          <button
            type="button"
            className="btn btn-go-home text-white btn-sm "
            onClick={() => {
              backLogin();
            }}
          >
            {logoutIcon}
            กลับสู่หน้าล็อคอิน
          </button>
        </div>
      </div>
    </>
  );
};

export { Nevbar };
