import { ConvertTH } from "../../widgets/form/ConvertTime";

const DetailModal = ({ data }: any) => {
  return (
    <>
      <table
        className="table table-borderless text-6F6B7D my-font"
        style={{ width: "100%" }}
      >
        <tbody>
          <tr className="p-2">
            <td className="fw-bold">เลขที่ใบชำระเงิน</td>
            <td>{data?.record_bill?.bill_number || ""}</td>
          </tr>
          <tr className="p-2">
            <td className="fw-bold">วันที่จดหน่วย</td>
            <td>{ConvertTH(data?.water_meter_record?.record_date) || ""}</td>
          </tr>
          <tr className="p-2">
            <td className="fw-bold">วันที่ครบกำหนด</td>
            <td>{ConvertTH(data?.due_date) || ""}</td>
          </tr>
          <tr className="p-2">
            <td className="fw-bold">วันที่ชำระ</td>
            <td>{ConvertTH(data?.record_bill?.issue_date) || ""}</td>
          </tr>
          <tr className="p-2">
            <td className="fw-bold">เลขมาตรครั้งล่าสุด</td>
            <td>{data?.water_meter_record?.previous_unit || ""}</td>
          </tr>
          <tr className="p-2">
            <td className="fw-bold">เลขมาตรปัจจุบัน</td>
            <td>{data?.water_meter_record?.current_unit || ""}</td>
          </tr>
          <tr className="p-2">
            <td className="fw-bold">หน่วยที่ใช้</td>
            <td>{data?.water_meter_record?.sum_unit || ""} หน่วย</td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export { DetailModal };
