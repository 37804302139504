import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import HomePage from "../pages/Home";
import RecordInvoicePage from "../pages/RecordInvoice";
import { ErrorsPage } from "../modules/errors/ErrorsPage";

const AppRoute = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route
          path="pecord-invoice/:area_number/:water_number/:meter_number"
          element={<RecordInvoicePage />}
        />
        {/* error */}
        <Route path="error/*" element={<ErrorsPage />} />

        {/* Page Not Found */}
        <Route path="*" element={<Navigate to="/error/404" />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoute;
