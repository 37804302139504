import axios from "axios";
// import https from "https";

const API_URL = process.env.REACT_APP_BACKEND_API;

export const Record_Invoices = `${API_URL}/profile/record_invoices`;

export function RecordInvoiceShow(
  area_number: string,
  water_number: string,
  meter_number: string
) {
  return axios
    .get(Record_Invoices, {
      params: {
        area_number: area_number,
        water_number: water_number,
        meter_number: meter_number,
      },
    })
    .catch((err) => console.log(err));
}
